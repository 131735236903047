import React, {useEffect, useState} from 'react';
import {
  Card,
  Row,
  Col,
  Modal,
  Spinner,
  Form,
  Badge,
  Button, CardBody,
} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {AiOutlineDown, AiOutlineUp} from 'react-icons/ai';
import moment from 'moment';
import api from '../helpers/api';
import {useAuth} from '../hooks/useAuth';
import {MdDownload} from 'react-icons/md';
import 'moment/locale/pt-br';

moment.locale('pt-br');

const Records = ({outData = [{}], changeDate = '', getMedicalRecords = []}) => {
  const [data, setData] = useState([]);
  const [records, setRecords] = useState();
  const [sort, setSort] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [dependents, setDependents] = useState([]);
  const [filterMonth, setFilterMonth] = useState('');
  const [filterYear, setFilterYear] = useState('');
  const [availableMonths, setAvailableMonths] = useState([]);
  const navigate = useNavigate();
  const {user} = useAuth();
  const [setToast] = useState({});

  const fetchDependents = async () => {
    try {
      const URL_ = `/titular/${user.OwnerId}/dependentes`;
      const METHOD = 'GET';
      const resp = await api({url: URL_, method: METHOD});
      setDependents(resp.data.data);
    } catch (error) {
      setToast({
        show: true,
        title: 'Erro',
        variant: 'error',
        content: 'Erro ao carregar dependentes',
        delay: 3000,
      });
    }
  };

  const handleOpenModal = async (appointmentId) => {
    setShowModal(true);
    setLoading(true);
    try {
      const response = await api.get(`video/atendimento/${appointmentId}`);
      const {data} = response.data;
      setSelectedAppointment(data);
    } catch (error) {
      console.error('Erro ao buscar detalhes do atendimento', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedAppointment(null);
  };

  const handleChange = () => {
    const newData = outData.filter(
        (item) => cleanString(item['ScheduleAt'], changeDate),
    );
    if (JSON.stringify(newData) !== JSON.stringify(data)) {
      setData(newData);
      setRecords(
          buildRecords(newData, navigate, handleOpenModal),
      );
    }
  };

  const cleanString = (string1, string2) => {
    if (typeof string1 !== 'string' && typeof string1 !==
        'number') return false;
    if (typeof string2 !== 'string' && typeof string2 !==
        'number') return false;

    return string1.toString().
        replaceAll(':', '').
        replaceAll('-', '').
        includes(string2.toString().replaceAll('-', '').replaceAll(':', ''));
  };

  const toggleSort = () => {
    const sortedData = [...data].sort((a, b) => {
      return sort
          ? new Date(a.ScheduledAt) - new Date(b.ScheduledAt)
          : new Date(b.ScheduledAt) - new Date(a.ScheduledAt);
    });
    setSort(!sort);
    setData(sortedData);
    setRecords(
        buildRecords(sortedData, navigate, handleOpenModal),
    );
  };

  const filterByMonthYear = () => {
    const filteredData = outData.filter((item) => {
      const appointmentDate = moment(item.ScheduledAt);
      const matchesMonth = filterMonth
          ? appointmentDate.month() + 1 === parseInt(filterMonth)
          : true;
      const matchesYear = filterYear
          ? appointmentDate.year() === parseInt(filterYear)
          : true;
      return matchesMonth && matchesYear;
    });
    setData(filteredData);
    setRecords(
        buildRecords(filteredData, navigate, handleOpenModal),
    );
  };

  const updateAvailableMonths = () => {
    if (filterYear) {
      const monthsForYear = outData.filter(
          (item) => moment(item.ScheduledAt).year() === parseInt(filterYear)).
          map((item) => moment(item.ScheduledAt).month() + 1);
      setAvailableMonths([...new Set(monthsForYear)].sort((a, b) => a - b));
      setFilterMonth('');
    } else {
      setAvailableMonths(
          [...new Set(outData.map(
              (item) => moment(item.ScheduledAt).month() + 1))].sort(
              (a, b) => a - b),
      );
    }
  };

  useEffect(() => {
    fetchDependents();
  }, []);

  useEffect(() => {
    handleChange();
    const sortedData = [...outData].reverse();
    setData(sortedData);
    setRecords(
        buildRecords(sortedData, navigate, handleOpenModal),
    );
  }, [outData]);

  useEffect(() => {
    updateAvailableMonths();
  }, [filterYear]);

  return (
      <>
        <Row xs={3} className={"gap-2"}>
          <Col lg={2} xs={3}>
            <Button
                className="bg-transparent border-0 text-black"
                onClick={toggleSort}
            >
              Data {sort ? <AiOutlineUp/> : <AiOutlineDown/>}
            </Button>
          </Col>
          <Col lg={2} xs={12}>
            <Form.Select
                value={filterMonth}
                className="border-primary"
                onChange={(e) => setFilterMonth(e.target.value)}
                disabled={!filterYear && availableMonths.length === 0}
            >
              <option value="">Todos os Meses</option>
              {availableMonths.map((month) => (
                  <option key={month} value={month}>
                    {moment().month(month - 1).format('MMMM')}
                  </option>
              ))}
            </Form.Select>
          </Col>
          <Col lg={2} xs={12}>
            <Form.Select
                value={filterYear}
                className="border-primary"
                onChange={(e) => setFilterYear(e.target.value)}
            >
              <option value="">Todos os Anos</option>
              {Array.from(
                  new Set(
                      outData.map((item) => moment(item.ScheduledAt).year())),
              ).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
              ))}
            </Form.Select>
          </Col>
          <Col>
            <Button onClick={filterByMonthYear} variant="primary">
              Filtrar
            </Button>
          </Col>
        </Row>
        {records}

        <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
          <Modal.Header closeButton>
            <Modal.Title>Detalhes do Atendimento</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="p-3">
              {isLoading ? (
                  <div
                      className="d-flex justify-content-center align-items-center">
                    <Spinner animation="border" role="status"/>
                    <span className="ms-2">Carregando...</span>
                  </div>
              ) : selectedAppointment ? (
                  selectedAppointment.doctor ? (
                      <Row>
                        <Col xs={12} className={'mb-3'}>
                          <h4>{selectedAppointment.doctor.name}</h4>
                          <p>
                              <span style={{color: 'black'}}>
                                CRM: {selectedAppointment.doctor.crm} - {selectedAppointment.doctor.crmUF}
                                <br/>
                                {selectedAppointment.specialty.name}
                              </span>
                          </p>
                          <p>
                              <span style={{color: 'black'}}>
                                Paciente: {selectedAppointment.patientName}
                              </span>
                          </p>
                          <p>
                              <span style={{color: 'black'}}>
                                Data/Hora do atendimento: {moment(
                                  selectedAppointment.createDate).
                                  format('DD/MM/YY HH:mm')}
                              </span>
                          </p>
                        </Col>
                        <Col xs={12}>
                          <p>
                            <strong>Queixa
                              principal: </strong>
                          </p>
                          <p>
                            <span style={{color: 'black'}}>
                              {selectedAppointment.medicalrecord?.diseaseHistory}
                            </span>
                          </p>
                        </Col>
                        <Col xs={12}>
                          <p>
                            <strong>Orientação
                              médica: </strong>
                          </p>
                          <p>
                              <span style={{color: 'black'}}>
                                {selectedAppointment.medicalrecord?.orientation}
                              </span>
                          </p>
                        </Col>
                        <Col xs={12}>
                          <p>
                            <strong>Hipótese diagnóstica: </strong>
                          </p>
                          <p>
                            {selectedAppointment.medicalrecord?.hypothesis?.cids.map(
                                ({cod, name}) => (
                                    <Badge key={cod}
                                           bg={'success'}>
                                      {cod} - {name}
                                    </Badge>
                                ),
                            )}
                          </p>
                        </Col>
                        <Col xs={12}>
                          <p>
                            <strong>Downloads: </strong>
                          </p>
                          <p>
                            {selectedAppointment.prescriptions?.map(
                                ({prescriptionPdfUrl, prescriptionType}) => {
                                  const buttonText = {
                                    RECEITA: 'BAIXAR PRESCRIÇÃO',
                                    ATESTADO: 'BAIXAR ATESTADO',
                                    EXAME: 'BAIXAR EXAME',
                                    ENCAMINHAMENTO: 'BAIXAR ENCAMINHAMENTO',
                                  }[prescriptionType];
                                  return (
                                      <span key={prescriptionPdfUrl}
                                            className="mb-2 d-block">
                                        <a
                                            className="btn btn-primary w-40"
                                            href={prescriptionPdfUrl}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            download
                                        >
                                          <MdDownload/> {buttonText}
                                        </a>
                                      </span>
                                  );
                                },
                            )}
                          </p>
                        </Col>
                      </Row>
                  ) : (
                      <div className="alert alert-warning" variant="primary">
                        Esse prontuário não está mais disponível.
                      </div>
                  )
              ) : (
                  <div className="alert alert-danger"></div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleCloseModal}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
  );
};

const buildRecords = (pageData, navigate, handleOpenModal) => {
  return (
      <>
        {pageData.map((item, index) => (
            item.Status !== 4 ? null : (
                <Card key={index}
                      className="bg-transparent border-1 border-primary appointment-card g-2"
                      onClick={() => handleOpenModal(item.Id)}
                >
                  <CardBody>
                    <Row className="align-items-center text-primary">
                      <Col md={1} xs={4} className="text-center">
                        <small>
                          #{item.Id}
                        </small>
                        <br/>
                        <strong>
                          {moment(item.ScheduledAt).format('DD/MM ')}
                        </strong>
                      </Col>
                      <Col md={11} xs={8}>
                        <strong className={"text-secondary"}>
                          {item.PatientName}
                        </strong>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
            )
        ))}
      </>
  );
};

export default Records;
