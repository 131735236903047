import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useAudioRecorder} from "react-audio-voice-recorder";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {LiveAudioVisualizer} from "react-audio-visualize";
import {Card, Modal, Stack} from "react-bootstrap";

const MediaTest = ({token}) => {
    const navigate = useNavigate()
    const [step, setStep] = useState(0);
    const [modal, setModal] = useState(false);
    const recorder = useAudioRecorder();
    const getCam = () => {
        let constraints = {
            video: true,
        }
        navigator.mediaDevices.getUserMedia(constraints).then(function (mediaStream) {
            let video = document.querySelector('video');
            video.srcObject = mediaStream;
            video.play().then(() => {
            });
        }).catch(function (err) {
            console.log("Erro na câmera" + err.message);
        });
    }

    const handleNextStep = () => {
        setStep((prevState) => prevState + 1);
    }

    const startAudioRecording = () => {
        try {
            if (recorder && recorder.startRecording) {
                recorder.startRecording();
            }
             setTimeout(() => {
                 navigate('/video', {state: {token}});
             }, 3000);
        } catch (e) {
        }
    };

    useEffect(() => {
        getCam();
    }, []);

    useEffect(() => {
        if (step === 2) {
            startAudioRecording();
        }
    }, [step]);

    return (
        <>
            {modal &&
                <Modal show={modal} onHide={() => {
                    setModal(false)
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Ops!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col className={'text-center text-primary'}>
                                <p>Caso não consiga passar no teste, tente acessar de outro dispositivo ou entre em
                                    contato
                                    com o suporte através do chat, no canto inferior direito do Painel Médico. Você pode
                                    também <a>acessar o nosso FAQ</a>
                                </p>
                            </Col>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className={"bg-tok fw-bold w-100"}
                                variant={"outline-primary"}
                                onClick={() => {
                            setModal(false)
                        }}>OK!</Button>
                    </Modal.Footer>
                </Modal>}
            {step === 0 &&
                <React.Fragment>
                    <Container className={'text-center'}>
                        <Row className={"text-primary pt-4"}>
                            <Col>
                                <h4>Seja Bem vindo (a)!</h4>
                                <h6 className={"text-primary"}>Para assegurar a excelência do atendimento,
                                    realizaremos
                                    um teste do seu dispositivo como uma etapa fundamental antes de inicial o
                                    atendimento.
                                </h6>
                            </Col>
                        </Row>
                            <div id={'video-test-container'}>
                                <video style={{
                                    width: '100%',
                                    height: 'auto'
                                }}/>
                            </div>
                        <Row className={"mt-3"}>
                            <Col className={"d-flex justify-content-center align-items-center text-primary"}>
                                <h6>Você se visualiza na janela acima?</h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={'p-2'}>
                                <Button className={'fw-bold mx-1'}
                                        variant={"outline-primary"}
                                        style={{width: '300px'}}
                                        onClick={() => {
                                            handleNextStep()
                                        }}>Sim</Button>
                                <Button className={'fw-bold mx-1'}
                                        variant={"outline-danger"}
                                        style={{width: '300px'}}
                                        onClick={() => {
                                            setModal(true)
                                        }}>Não</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button variant={"outline-danger"}
                                        style={{width: '240px'}}
                                        onClick={() => {
                                            navigate('/dashboard')
                                        }}>Cancelar</Button>
                            </Col>
                        </Row>
                    </Container>
                </React.Fragment>
            }
            {step === 1 &&
                <React.Fragment>
                    <Container className={'text-center'}>
                        <Row className={"text-primary pt-4"}>
                            <Col>
                                <h4>Agora vamos testar o seu microfone!</h4>
                            </Col>
                        </Row>
                        <Card className={"my-4 p-5 border-2 border-primary bg-transparent text-center"}>
                            <Row>
                                <Col className={"p-5 text-primary"}>
                                    <p>Diga algo para validar o funcionamento do dispositivo.</p>
                                </Col>
                            </Row>
                        </Card>
                        <Stack gap={3} className="col-md-5 mx-auto" style={{width: "300px"}}>
                            <Button variant={"outline-primary"} onClick={() => {
                                handleNextStep()
                            }}>Vamos lá!</Button>
                            <Button variant={"outline-danger"} onClick={() => {
                                navigate('/dashboard')
                            }}>Cancelar</Button>
                        </Stack>
                    </Container>
                </React.Fragment>
            }
            {step === 2 &&
                <React.Fragment>
                    <Container className={'text-center'}>
                        <Row className={'pt-4'}>
                            <Col>
                                <h4 className={"text-primary"}>Vamos testar o seu microfone!</h4>
                                <p className={'text-primary'}>Diga algo para validar o funcionamento do dispositivo.</p>
                            </Col>
                        </Row>
                        <Card className="my-4 p-5 border-2 border-primary bg-transparent text-center">
                            <Col className="p-0" xs={12}>
                                {recorder.mediaRecorder && (
                                    <LiveAudioVisualizer
                                        mediaRecorder={recorder.mediaRecorder}
                                        width="100%"
                                        height="auto"
                                        barColor="primary"
                                    />
                                )}
                            </Col>
                        </Card>

                        <Row className={'text-primary'}>
                            <Col>
                                <p>Caso não consiga fazer o teste, tente acessar de outro dispositivo ou entre em
                                    contato
                                    com o suporte</p>
                            </Col>
                        </Row>
                    </Container>
                </React.Fragment>
            }
        </>
    )
}

export default MediaTest;