import {Card, InputGroup, Modal, ToastContainer} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useLocation, useNavigate} from 'react-router-dom';
import Bro from '../assets/images/fig-waiting.png';
import Doc from '../assets/images/fig-video-doctor.png';
import Image from 'react-bootstrap/Image';
import api from '../helpers/api';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import moment from 'moment';
import CustomToast from '../components/CustomToast';
import MediaTest from '../components/video/tests/MediaTest';
import {
  MdCalendarMonth,
  MdCalendarViewMonth, MdContentCopy,
  MdContentPaste,
} from 'react-icons/md';
import Form from 'react-bootstrap/Form';
import {BsSearch} from 'react-icons/bs';

const WaitingRoom = () => {
  const [appointment, setAppointment] = useState({});
  const [token, setToken] = useState();
  const [modal, setModal] = useState(false);
  const [toast, setToast] = useState({});
  const [test, setTest] = useState(false);
  const location = useLocation();
  const {id} = location.state;

  const {
    dateAppointment,
    patientName,
    status,
    videoRoomLink,
  } = {...appointment};

  const navigate = useNavigate();
  const formattedDate = moment(dateAppointment).format('DD/MM/YYYY [às] HH:mm');

  const getAppointment = useCallback(async () => {
    try {
      const URL_ = `video/atendimento/${id}`;
      const METHOD = 'GET';
      const PARAMS = {
        id: id,
      };

      const resp = await api({url: URL_, method: METHOD, params: PARAMS});
      const {data} = resp.data;

      setAppointment(data);
      if (data.status === 6) {
        clearInterval(intervalRef.current);
        const videoRoom = await api.get(`video/atendimento/${id}/token`);
        const {roomToken} = {...videoRoom.data.data};
        setToken(roomToken);
        await setToken({
          token: videoRoom.data.data['roomToken'],
          name: videoRoom.data.data['roomName'],
          patientName: `${data.patientName}${data.buyer.age}`,
        });
      } else {
        await setToken({
          token: 'placeholder',
          name: 'placeholder',
          patientName: `placeholder`,
        });
      }
    } catch (error) {
      const text = error.response?.data?.errors[0];
      setToast({
        show: true,
        variant: 'danger',
        title: 'Erro',
        content: text,
      });
    }
  }, [id]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setToast({
        show: true,
        variant: 'success',
        title: 'Link Copiado!',
        content: 'O link da sala foi copiado para a área de transferência.',
        delay: 3000,
      });
    }).catch((err) => {
      setToast({
        show: true,
        variant: 'danger',
        title: 'Erro',
        content: 'Falha ao copiar o link.',
      });
    });
  };

  const deleteAppointment = () => {
    setToast({show: false, delay: 3000});
    try {
      api.delete(`video/atendimento/${id}`).then();
      setModal(false);
      setToast({
        show: true,
        title: 'Sucesso',
        variant: 'success',
        content: 'Sua consulta foi cancelada! Estaremos aqui se precisar!',
        delay: toast.delay,
      });
      setTimeout(() => {
        navigate('/dashboard');
      }, 2000);
    } catch (err) {
      const text = err.response?.data?.errors[0];
      setToast({
        show: true,
        variant: 'danger',
        title: 'Erro',
        content: text,
      });
    }
  };
  const openTests = () => {
    setTest(true);
  };

  useEffect(() => {
    getAppointment().then();
  }, []);

  const intervalRef = useRef(null);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (!dateAppointment) {
        return false;
      }
      const diff = moment().diff(dateAppointment, 'seconds');
      if (diff >= 0) {
        getAppointment().then();
      }
    }, 30000);
    return () => clearInterval(intervalRef.current);
  });

  return (
      <>
        {modal && (
            <Modal show={modal} onHide={() => setModal(false)} centered>
              <Modal.Header closeButton>
                <Modal.Title>Cancelar agendamento</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <span>Sua consulta será cancelada. Deseja confirmar?</span>
              </Modal.Body>
              <Modal.Footer>
                <Button className={'bg-tok fw-bold'}
                        onClick={deleteAppointment}
                        variant={'outline-success'}>
                  Sim
                </Button>
                <Button className={'fw-bold'}
                        variant={'outline-danger'}
                        onClick={() => setModal(false)}>
                  Não
                </Button>
              </Modal.Footer>
            </Modal>
        )}

        <div>
          <ToastContainer id={'toastcontainer'} position={'top-center'}
                          className={'p-3'} style={{zIndex: 1}}>
            {toast.show && <CustomToast delay={toast.delay} title={toast.title}
                                        content={toast.content}
                                        variant={toast.variant}
                                        show={toast.show}
                                        isHTML={toast.isHTML}/>}
          </ToastContainer>
        </div>
        {!test &&
            <React.Fragment>
              <Container>
                <Row className={'text-center p-4 text-primary'}>
                  <Col>
                    <h1>Sala de Espera</h1>
                  </Col>
                </Row>
                <Row>
                  <Col sm={{span: 8, offset: 2}}>
                    <Card className={'border-2 border-grey bg-transparent'}>
                      <Row className={'text-center'}>
                        {status === 6 && (
                            <Col className={'mt-3 col-lg-12'}>
                              <Image src={Doc} style={{maxWidth: '320px'}}
                                     className={'w-100 '}/>
                              <h6 className="text-primary mt-2">
                                {patientName}, estamos te aguardando para
                                realizar a sua teleconsulta!
                              </h6>
                            </Col>
                        )}
                        {status !== 6 && (
                            <Col className={'mt-3'}>
                              <Image src={Bro} style={{maxWidth: '350px'}}
                                     className={'w-100'}/>
                              <h6 className="text-primary mt-2">Teleconsulta
                                agendada para {patientName} para o
                                dia {formattedDate}</h6>
                            </Col>
                        )}
                      </Row>
                      <Row className={'text-center py-4'}>
                        <Col>
                          {status === 6 && (
                              <>
                                <Button className={'fw-bold'}
                                        variant={'primary'}
                                        style={{width: '300px'}}
                                        onClick={openTests}>
                                  Entrar na Sala
                                </Button>
                                <div className="mt-3">
                                  <p className={'text-primary'}>
                                    ATENÇÃO! Caso tenha alguma dificuldade de
                                    acesso pelo aplicativo,
                                    <br/>
                                    copie link abaixo e cole na barra de
                                    endereço do seu navegador de internet.
                                  </p>
                                  <InputGroup
                                      className="btn-outline-danger justify-content-center"
                                  >
                                    <InputGroup.Text
                                        className="border-primary text-center"
                                        style={{
                                          maxWidth: '200px',
                                          overflow: 'hidden',
                                          whiteSpace: 'nowrap',
                                        }}
                                    >
                                      {videoRoomLink}
                                    </InputGroup.Text>
                                    <Button
                                        className="d-flex align-items-center"
                                        variant="outline-primary"
                                        onClick={() => {
                                          navigator.clipboard.writeText(videoRoomLink);
                                          setToast({
                                            show: true,
                                            title: 'Link Copiado',
                                            variant: 'success',
                                            content: 'O link da sala foi copiado para a área de transferência!',
                                          });
                                        }}
                                    >
                                      <MdContentCopy />
                                    </Button>
                                  </InputGroup>

                                </div>
                              </>
                          )}
                          {status !== 6 && (
                              <Button className={'fw-bold'}
                                      variant={'danger'}
                                      style={{width: '300px'}}
                                      onClick={() => {
                                        setModal(true);
                                      }}>
                                Cancelar Agendamento
                              </Button>
                          )}
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col className={'text-center py-3'}>
                    <Button variant={'outline-danger'}
                            style={{width: '240px'}}
                            onClick={() => {
                              setTimeout(() => {
                                navigate('/dashboard');
                              }, 1000);
                            }}>Voltar
                    </Button>
                  </Col>
                </Row>
              </Container>
            </React.Fragment>
        }
        {test && <MediaTest token={token}/>}
      </>
  );
};

export default WaitingRoom;