import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URI,
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Basic ' + process.env.REACT_APP_BASIC,
    },
})
export const APPOINTMENT_CREATED = 1;
export const APPOINTMENT_ACCEPTED = 2;
export const APPOINTMENT_CANCELED = 3;
export const APPOINTMENT_FINISHED = 4;
export const DOCTOR_CHANGED = 5;
export const DOCTOR_VIDEO_ONLINE = 6;
export const APPOINTMENT_DATE_CHANGED = 7;
export const IMPORTATION_STARTED = 8;
export const IMPORTATION_COMPLETED = 9;
export const PATIENT_VIDEO_NOSHOW = 10;
export const DOCTOR_VIDEO_NOSHOW = 11;
export const NURSE_FOLLOWUP = 12;

export const appointmentStatusMessages = {
    [APPOINTMENT_CREATED]: "Criado",
    [APPOINTMENT_ACCEPTED]: "Aceito pelo médico",
    [APPOINTMENT_CANCELED]: "Cancelado",
    [APPOINTMENT_FINISHED]: "Finalizado",
    [DOCTOR_CHANGED]: "Alteração de médico",
    [DOCTOR_VIDEO_ONLINE]: "Médico entrou na sala",
    [APPOINTMENT_DATE_CHANGED]: "Alteração de data",
    [IMPORTATION_STARTED]: "Importação Iniciada",
    [IMPORTATION_COMPLETED]: "Importação Finalizada",
    [DOCTOR_VIDEO_NOSHOW]: "Médico não entrou na sessão",
    [PATIENT_VIDEO_NOSHOW]: "Paciente não entrou na sessão",
    [NURSE_FOLLOWUP]: "Atendimento acompanhado por enfermeiro",
};


export default api;