import {Card, Modal, Spinner, ToastContainer} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, {useEffect, useState} from 'react';
import moment from 'moment';
import api from '../helpers/api';
import CustomToast from '../components/CustomToast';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../hooks/useAuth';

const Scheduling = () => {
  const [hours, setHours] = useState([]);
  const [toast, setToast] = useState({});
  const [modalSelectPatient, setModalSelectPatient] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [selectedHour, setSelectedHour] = useState(null);
  const [loading, setLoading] = useState(true);
  const {user, OwnerId} = useAuth();
  const navigate = useNavigate();
  const [dependents, setDependents] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const fetchDependents = async () => {
    try {
      const URL_ = `/titular/${user.OwnerId}/dependentes`;
      const METHOD = 'GET';
      const PARAMS = {
        ownerId: OwnerId,
      };

      const resp = await api({url: URL_, method: METHOD, params: PARAMS});
      const {data} = resp.data;

      setDependents(data);
    } catch (error) {
      setToast({
        show: true,
        title: 'Erro',
        variant: 'error',
        content: 'Erro ao carregar pacientes',
        delay: 3000,
      });
    }
  };

  const getHours = async () => {
    try {
      const URL_ = 'video/atendimentos/horarios';
      const METHOD = 'GET';

      const resp = await api({url: URL_, method: METHOD});
      const {data} = resp.data;

      setHours(data.splice(0, 48));
    } catch (error) {
      setToast({
        show: true,
        title: 'Erro',
        variant: 'error',
        content: 'Erro ao listar horários',
        delay: 3000,
      });
      setTimeout(() => {
        navigate('/dashboard');
      }, 2000);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectHour = (hour) => {
    setSelectedHour(hour);
    setModalSelectPatient(true);
  };

  const handleConfirm = async () => {
    setModalConfirm(false);
    setToast({show: false, delay: 3000});

    try {
      const URL_ = 'video/atendimento';
      const METHOD = 'POST';
      const DATA = {
        dateAppointment: selectedHour,
        patientId: selectedPatient?.OwnerId || selectedPatient?.Id,
      };

      await api({url: URL_, method: METHOD, data: DATA});

      setToast({
        show: true,
        title: 'Sucesso',
        variant: 'success',
        content: 'Consulta agendada com sucesso.',
        delay: 3000,
      });
      setTimeout(() => {
        navigate('/dashboard');
      }, 4000);
    } catch (error) {
      const text = error.response.data.errors ?
          error.response.data.errors[0] :
          'Erro desconhecido';
      setToast(
          {
            show: true,
            variant: 'danger',
            title: 'Erro',
            content: text,
            delay: 5000,
          },
      );
    }
  };

  useEffect(() => {
    fetchDependents();
    getHours();
  }, []);

  return (<>
    <div className={'sticky-bottom'}>
      <ToastContainer id={'toastcontainer'}
                      position={'top-center'}
                      className={'p-3'}
                      style={{zIndex: 1}}
      >
        {toast.show && <CustomToast
            delay={toast.delay}
            title={toast.title}
            content={toast.content}
            variant={toast.variant}
            show={toast.show}
            isHTML={toast.isHTML}
        />}
      </ToastContainer>
    </div>
    <Modal show={modalSelectPatient}
           onHide={() => setModalSelectPatient(false)}
           centered>
      <Modal.Header closeButton className={''}>
        <Modal.Title>Para quem você deseja agendar?</Modal.Title>
      </Modal.Header>
      <Modal.Body className={''}>
        <div
            role="button"
            onClick={() => {
              setSelectedPatient(user);
              setModalConfirm(true);
              setModalSelectPatient(false);
            }}
            className="my-2 p-2"
            // key={OwnerId}
        >
          <div className="p-2 shadow-sm">
            <Row>
              <Col sm={12} lg={2}>
                <div
                    className="d-flex justify-content-center align-items-center">
                  <div
                      className="rounded-circle shadow-sm bg-primary d-flex justify-content-center align-items-center"
                      style={{
                        width: '50px',
                        height: '50px',
                      }}
                  >
                  <span className="text-uppercase text-white">
                    {user.FullName.charAt(0)}
                  </span>
                  </div>
                </div>
              </Col>
              <Col sm={12} lg={10}>
                <span style={{fontSize: '16px'}}>
                  {user.FullName.length > 1 ?
                      `${user.FullName.substring(0, 65)}...` :
                      user.FullName}
                </span>
                <br/>
                <small style={{fontSize: '10px'}}>
                  Você
                </small>
              </Col>
            </Row>
          </div>
        </div>
        {dependents.map((item) => (
            <div
                role="button"
                className="my-2 p-2"
                key={item.Id}
                onClick={() => {
                  setSelectedPatient(item);
                  setModalConfirm(true);
                  setModalSelectPatient(false);
                }}>
              <div className="p-2 shadow-sm">
                <Row>
                  <Col sm={12} lg={2}>
                    <div
                        className="d-flex justify-content-center align-items-center">
                      <div
                          className="rounded-circle shadow-sm bg-primary d-flex justify-content-center align-items-center"
                          style={{
                            width: '50px',
                            height: '50px',
                          }}
                      >
                      <span className="text-uppercase text-white">
                        {item.FullName.charAt(0)}
                      </span>
                      </div>
                    </div>
                  </Col>
                  <Col sm={12} lg={10}>
                    <span style={{fontSize: '16px'}}>
                      {item.FullName.length > 65 ?
                          `${item.FullName.substring(0, 65)}...` :
                          item.FullName}
                    </span>
                    <br/>
                    <small style={{fontSize: '10px'}}>
                      {item.DegreeOfRelatedness}
                    </small>
                  </Col>
                </Row>
              </div>
            </div>))}
      </Modal.Body>
      <Modal.Footer className={''}>
        <Button variant="danger"
                onClick={() => setModalSelectPatient(false)}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
    <Modal
        show={modalConfirm}
        onHide={() => setModalConfirm(false)}
        centered>
      <Modal.Body className={'text-center'}>
                    <span
                        className="rounded-circle bg-primary text-white d-inline-block text-center my-2"
                        style={{
                          width: '60px',
                          height: '60px',
                          lineHeight: '60px',
                        }}>
                      {selectedPatient ?
                          selectedPatient.FullName.charAt(0) :
                          user.FullName.charAt(0)}
                    </span>
        <h6> Deseja confirmar sua consulta para {selectedPatient ?
            selectedPatient.FullName.split(' ')[0] :
            user.FullName.split(' ')[0]}?
        </h6>
        <Row className={'text-center my-2'}>
          <Col lg={6}>
            <span>Data:</span>
            <h6 className={'text-primary'}>
              {moment(selectedHour).format('DD/MM/YYYY')}
            </h6>
          </Col>
          <Col lg={6}>
            <span>Horário:</span>
            <h6 className={'text-primary'}>
              {moment(selectedHour).format('HH:mm')}
            </h6>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className={''}>
        <Button
            className={'w-25'}
            variant={'outline-primary'} onClick={handleConfirm}>
          Sim
        </Button>
        <Button
            className={'w-25'}
            variant={'outline-danger'}
            onClick={() => setModalConfirm(false)}>
          Não
        </Button>
      </Modal.Footer>
    </Modal>
    <Container sm={{offset: 2, span: 8}}>
      <Row>
        <Col className={'text-center mt-4'}>
          <h1>Escolha um horário</h1>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          {loading ? (<Spinner animation="border" role="status"/>) : (
              <Card
                  className={'d-flex justify-content-center align-items-center shadow mt-4 p-sm-4 p-md-5 border-1 bg-transparent'}>
                <Row>
                  {hours.map((hour, index) => (
                      <Col xs={4} sm={3} md={2}
                           className={'my-1 d-grid justify-content-center'}
                           key={index}
                      >
                        <Button
                            variant={'outline-primary'}
                            onClick={() => handleSelectHour(hour)}
                        >
                          {moment(hour).format('HH:mm')}
                        </Button>
                      </Col>
                  ))}
                </Row>
              </Card>
          )}
        </Col>
      </Row>
      <Row className={'mt-4 text-center'}>
        <Col>
          <Button variant={'outline-danger'}
                  onClick={() => navigate('/dashboard')}>
            Voltar
          </Button>
        </Col>
      </Row>
    </Container>
  </>);
};

export default Scheduling;