import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Alert from "react-bootstrap/Alert";
import {FloatingLabel} from "react-bootstrap";
import {Link} from 'react-router-dom';
import React, {useState} from "react";
import Logo from "../assets/images/logo-04.png";
import api from "../helpers/api";
import {useAuth} from "../hooks/useAuth";
import InputMask from "react-input-mask";
import {useFormik} from "formik";
import * as Yup from "yup";
import Row from "react-bootstrap/Row";
import {FaEye, FaEyeSlash, FaExclamationTriangle} from "react-icons/fa";
import Spinner from 'react-bootstrap/Spinner';

const YEAR = new Date().getFullYear();
const Login = () => {
    const [error, setError] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const {Login} = useAuth();
    const {handleSubmit,
        errors,
        values,
        touched,
        handleChange
    } = useFormik({
        initialValues: {
            login: "",
            password: "",
        },
        validationSchema: Yup.object().shape({
            login: Yup.string().required("Informe seu CPF"),
            password: Yup.string().required("Informe sua senha"),
        }),
        onSubmit: async (v) => {
            setLoading(true);
            try {
                const {login, password} = v;
                const resp = await api.post("app/usuario/autenticar", {login, password});
                const {data} = {...resp.data};
                Login(data);
            } catch (err) {
                if (err.response) {
                    const {errors} = {...err.response.data};
                    Object.entries(errors).forEach(([key, value]) => {
                        setError(value, key);
                    });
                }
            }
            setLoading(false);
        },
    });

    return (
        <Container
            className="d-flex justify-content-center align-items-center vh-100">
            <form onSubmit={handleSubmit} className="w-100" style={{maxWidth: 480}}>
                <Row className="d-flex flex-column gap-3">
                    <div className="text-center">
                        <Image src={Logo} className="img-fluid w-50"/>
                        <h5>Atendimento por Teleconsulta</h5>
                    </div>
                    <Form.Group>
                        {error && (
                            <Alert variant="danger">
                                <FaExclamationTriangle className="me-2"/>
                                {error}
                            </Alert>
                        )}
                        <FloatingLabel label={"CPF"}>
                            <InputMask
                                placeholder={"CPF"}
                                mask="999.999.999-99"
                                id="login"
                                value={values.login}
                                onChange={handleChange}
                                className="border-primary"
                            >
                                {(inputProps) => <Form.Control type="text" {...inputProps} />}
                            </InputMask>
                        </FloatingLabel>
                        <Form.Text className="text-muted"></Form.Text>
                        {touched.login && errors.login ? (
                            <span className="error">{errors.login}</span>
                        ) : null}
                    </Form.Group>
                    <Form.Group className="position-relative">
                        <FloatingLabel label={"Senha"}>
                            <Form.Control
                                type={showPassword ? "text" : "password"}
                                placeholder="Digite sua senha"
                                id="password"
                                value={values.password}
                                onChange={handleChange}
                                maxLength={6}
                                className="border-primary"
                            />
                        </FloatingLabel>
                        <Button
                            variant="primary"
                            onClick={() => setShowPassword(!showPassword)}
                            style={{
                                position: "absolute",
                                right: 10,
                                top: "50%",
                                transform: "translateY(-50%)",
                                border: "none",
                                background: "transparent",
                                color: "gray",
                            }}
                        >
                            {showPassword ? <FaEyeSlash/> : <FaEye/>}
                        </Button>
                        <Form.Text className="text-muted"></Form.Text>
                        {touched.password && errors.password ? (
                            <span className="error">{errors.password}</span>
                        ) : null}
                    </Form.Group>
                    <Form.Group>
                        <Button className={"btn-primary w-100"} type="submit" size={"lg"}>
                            {loading ? <Spinner animation="border"/> : "Entrar"}
                        </Button>
                    </Form.Group>
                    <Link className={"text-dark custom-a text-center my-2"} to={"/recovery"}>
                        Recuperar Senha | Primeiro Acesso
                    </Link>
                    <small className={"text-center small"}>© {YEAR} | TokSaúde</small>
                </Row>
            </form>
        </Container>
    );
};

export default Login;
