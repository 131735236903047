import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import ScreenFormat from "../components/ScreenFormat";
import Video, { createLocalTracks } from 'twilio-video'
import {Modal, ToastContainer} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import CustomToast from "../components/CustomToast";
import CustomOverlay from "../components/CustomOverlay";
import ControlsBar from "../components/video/controlsBar/ControlsBar";
import {AiOutlineUser} from "react-icons/ai";

const Telemedicine = () => {
    const [room, setRoom] = useState(null)
    const [patientName, setPatientName] = useState("")
    const [doctorName, setDoctorName] = useState("")
    const [modal, setModal] = useState()
    const [toast, setToast] = useState({})
    const [remote, setRemote] = useState(false)
    const [remoteText, setRemoteText] = useState("")
    const [tracks, setTracks] = useState(null)
    const [camIcon, setCamIcon] = useState(false)
    const [audIcon, setAudIcon] = useState(false)

    const navigate = useNavigate();
    const {state: {token}} = useLocation()

    const handleCam = () => {
        setCamIcon(!camIcon)
        tracks.video.forEach((item) => {
            item.track.isEnabled? item.track.disable():item.track.enable()
        })

        const localDiv = document.getElementById("local")
        camIcon? localDiv.style.visibility = "visible": localDiv.style.visibility = "hidden"

        return camIcon;
    }
    const handleMic = () => {
        setAudIcon(!audIcon)
        tracks.audio.forEach((item) => {
            item.track.isEnabled? item.track.disable():item.track.enable()
        })

        return audIcon;
    }

    const handleExit = () =>
    {
        if(tracks)
        {
            tracks.video.forEach((item) => {
                const detached = item.track.detach()
                detached.forEach(element => element.remove())
            })

            tracks.audio.forEach((item) => {
                const detached = item.track.detach()
                detached.forEach(element => element.remove())
            })
        }
        if(room)
            room.disconnect()

        navigate("/nps")
    }

    useEffect(() => {
        if(tracks !== null)
            return;

        createLocalTracks({audio: true, video: {width: 640}}).then(local => {
            setTracks(local)
            setPatientName(token.patientName)
            setToast({show: true, title: `${token.patientName} entrou na sala.`, delay: 2000, content: "", variant: "success"})

            local.forEach(l => {
                if(document.getElementById("local"))
                    document.getElementById("local").appendChild(l.attach())
            })

            return Video.connect(token.token, {name: token.nameRoom}).then(Room => {
                const remoteDiv = document.getElementById("remote")

                setRoom(Room)
                setTracks({video: Room.localParticipant.videoTracks, audio: Room.localParticipant.audioTracks});

                Room.participants.forEach(remote => {
                    setDoctorName(remote.identity)
                    remote.tracks.forEach((publication) => {

                        if (publication.isSubscribed) {
                            if(remoteDiv)
                                remoteDiv.appendChild(publication.track.attach())
                        }
                    })

                    remote.on("trackSubscribed", track => {
                        if(remoteDiv)
                            remoteDiv.appendChild(track.attach())
                    })

                    remote.on("trackEnabled", track => {
                        setRemote(false)
                        if(track.kind === "video")
                            setRemoteText("O doutor está com a câmera desligada.")
                        if(track.kind === "audio")
                            setRemoteText("O doutor está com o microfone desligado.")
                    })

                    remote.on("trackDisabled", track => {
                        setRemote(true)
                        if(track.kind === "video")
                            setRemoteText("O doutor está com a câmera desligada.")
                        if(track.kind === "audio")
                            setRemoteText("O doutor está com o microfone desligado.")
                    })
                })

                Room.on("disconnected", room => {

                    room.localParticipant.tracks.forEach(publication => {
                        const attached = publication.track.detach()
                        attached.forEach(element => element.remove())
                    })

                    handleExit()
                })

                Room.on("participantConnected", (remote) => {

                    /**
                     * @description Remover a tag de audio
                     */
                    for(let element of remoteDiv.children)
                    {
                        if(element.id === "local")
                        {
                            continue;
                        }
                        element.remove()
                    }

                    /**
                     * @description Remover a tag de video
                     */
                    for(let element of remoteDiv.children)
                    {
                        if(element.id === "local")
                        {
                            continue;
                        }
                        element.remove()
                    }

                    remote.tracks.forEach((publication) => {
                        if (publication.isSubscribed) {
                            remoteDiv.appendChild(publication.track.attach())
                        }
                    })

                    remote.on("trackSubscribed", track => {
                        remoteDiv.appendChild(track.attach())
                        setRemote(false)
                        if(track.kind === "video")
                            setRemoteText("O doutor está com a câmera desligada.")
                        if(track.kind === "audio")
                            setRemoteText("O doutor está com o microfone desligado.")
                    })

                    remote.on("trackUnsubscribed", track => {
                        remoteDiv.appendChild(track.attach())
                        setRemote(true)
                        if(track.kind === "video")
                            setRemoteText("O doutor está com a câmera desligada.")
                        if(track.kind === "audio")
                            setRemoteText("O doutor está com o microfone desligado.")
                    })

                })

                setRoom(Room)
            }).catch(err => {
                console.log(err);
            })
        })
    }, [room, patientName, doctorName, tracks, toast, remote]);

    return(
        <>
            <div className={"sticky-bottom"}>
                <ToastContainer id={"toastcontainer"} position={"top-start"} className={"p-3"} style={{zIndex: 1}}>
                    {toast.show && <CustomToast show={toast.show} delay={toast.delay} title={toast.title} variant={toast.variant} isHTML={false}/>}
                </ToastContainer>
            </div>
            <Modal show={modal}
                   onHide={() => setModal(false)}
                   centered>
                <Modal.Header className={''} closeButton>
                    <Modal.Title>Encerrar consulta</Modal.Title>
                </Modal.Header>
                <Modal.Body className={''}>
                    <span>Deseja sair da sala e encerrar a consulta?</span>
                </Modal.Body>
                <Modal.Footer className={''}>
                    <Button variant={'outline-primary'}
                            onClick={() => {
                                handleExit()
                            }}>Sim</Button>
                    <Button
                        variant={'outline-danger'}
                        onClick={() => {
                            setModal(false)
                        }}>Não</Button>
                </Modal.Footer>
            </Modal>
            <CustomOverlay backgroundColor={"black"} show={remote} content={remoteText} icon={<AiOutlineUser className={"mb-5"} style={{height: "4em", width: "4em"}} />} />
            <ScreenFormat showButtons={tracks} setModal={setModal} handleCam={handleCam} handleMic={handleMic} patientName={patientName} doctorName={doctorName} />
            <div className={"d-flex justify-content-center w-100 position-fixed bottom-0 mb-5"}>
                <ControlsBar handleCam={handleCam} handleMic={handleMic} setModal={setModal}/>
            </div>
        </>
    )
}

export default Telemedicine;