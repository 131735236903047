import {createContext, useCallback, useContext, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import useLocalStorage from './useLocalStorage';
import PropTypes from "prop-types";

// const {REACT_APP_INSTANCE} = process.env.REACT_APP_INSTANCE;
const AuthContext = createContext(undefined);

const AuthProvider = (props) => {
    const {
        initialPath,
        loginPath,
        children
    } = props;

    const [user, setUser] = useLocalStorage(`PWA-Telemedicine:user`, null);
    const navigate = useNavigate();

    // call this function when you want to authenticate the user
    const Login = useCallback(async (data) => {
        setUser(data);
        navigate(initialPath);
    }, [initialPath, navigate, setUser]);

    // call this function to sign out logged-in user
    const logout = useCallback(() => {
        setUser(null);
        navigate(loginPath, {replace: true});
    }, [loginPath, navigate, setUser]);

    const value = useMemo(
        () => ({
            user,
            Login,
            logout,
        }),
        [Login, logout, user],
    );
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
    children: PropTypes.any,
    loginPath: PropTypes.string,
    initialPath: PropTypes.string
}

export const useAuth = () => {
    return useContext(AuthContext);
};

export default AuthProvider;

