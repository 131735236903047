import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Card, FloatingLabel, ToastContainer} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import api from "../helpers/api";
import Logo from "../assets/images/logo-04.png";
import Image from "react-bootstrap/Image";
import {useAuth} from "../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import * as Yup from "yup";
import Alert from "react-bootstrap/Alert";
import {FaExclamationTriangle, FaEye, FaEyeSlash} from 'react-icons/fa';
import CustomToast from "../components/CustomToast";
import Spinner from "react-bootstrap/Spinner";

const Account = () => {
    const [error, setError] = useState(null);
    const [toast, setToast] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {user} = useAuth();
    const {Id} = user;
    const [showPassword, setShowPassword] = useState(false);
    const {handleSubmit, values, handleChange, touched, errors} = useFormik({
        initialValues: {
            login: user.Login,
            password: '',
            newPassword: '',
        },
        validationSchema: Yup.object().shape({
            password: Yup.string().required("Informe sua senha")
                .length(6, "Sua senha deve ser formada por 6 dígitos")
                .test('passwords-match', 'As senhas não podem ser iguais', function (value) {
                    return value !== this.parent.newPassword;
                }),
            newPassword: Yup.string().required("Informe sua nova senha")
                .length(6, "Sua senha deve ser formada por 6 dígitos")
        }),
        onSubmit: async (values) => {
            setLoading(true)
            try {
                const {login, password, newPassword} = values;
                await api.put(`app/usuario/${Id}/senha/trocar`, {
                    login, password, newPassword
                });

                setToast({
                    show: true,
                    title: "Sucesso",
                    variant: "success",
                    content: "Senha atualizada com sucesso.",
                    delay: toast.delay
                })
                navigate('/dashboard');
            } catch (err) {
                if (err.response) {
                    const {errors} = {...err.response.data};
                    Object.entries(errors).forEach(([key, value]) => {
                        setError(value, key);
                    })
                }
            }
            setLoading(false);
        }
    })

    return (
        <>
            <Container className="d-flex justify-content-center align-items-center vh-100">
                <div className={'sticky-bottom'}>
                    <ToastContainer id={'toastcontainer'} position={'top-center'}
                                    className={'p-3'} style={{zIndex: 1}}>
                        {toast.show && <CustomToast delay={toast.delay} title={toast.title}
                                                    content={toast.content}
                                                    variant={toast.variant}
                                                    show={toast.show}
                                                    isHTML={toast.isHTML}/>}
                    </ToastContainer>
                </div>
                <form onSubmit={handleSubmit}>
                    <Row style={{maxWidth: 480}}>
                        <Col className={"text-center"}>
                            <Image src={Logo} className={'img-fluid w-50'}/>
                        </Col>
                        <Form className={"d-flex flex-column gap-3"}>
                            <h5 className='btn-outline-primary'>
                                Trocar Senha
                            </h5>
                            <>
                                {error && (
                                    <Alert variant="danger">
                                        <FaExclamationTriangle className="me-2"/>
                                        {error}
                                    </Alert>
                                )}
                                <Form.Group className="position-relative">
                                    <FloatingLabel label={"Senha atual"}>
                                        <Form.Control
                                            type={showPassword ? "text" : "password"}
                                            placeholder="Digite sua senha"
                                            id="password"
                                            value={values.password}
                                            onChange={handleChange}
                                            maxLength={6}
                                            className="border-primary"
                                        />
                                    </FloatingLabel>
                                    <Button
                                        variant="primary"
                                        onClick={() => setShowPassword(!showPassword)}
                                        style={{
                                            position: "absolute",
                                            right: 10,
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                            border: "none",
                                            background: "transparent",
                                            color: "gray",
                                        }}
                                    >
                                        {showPassword ? <FaEyeSlash/> : <FaEye/>}
                                    </Button>
                                    <Form.Text className="text-muted"></Form.Text>
                                    {touched.password && errors.password ? (
                                        <span className="error">{errors.password}</span>
                                    ) : null}
                                </Form.Group>
                                <Form.Group>
                                    <Form.Text className="text-muted"></Form.Text>
                                </Form.Group>
                                <Form.Group className="position-relative">
                                    <FloatingLabel label={"Nova senha"}>
                                        <Form.Control
                                            type={showPassword ? "text" : "password"}
                                            placeholder="Digite sua senha nova"
                                            id="newPassword"
                                            value={values.newPassword}
                                            onChange={handleChange}
                                            maxLength={6}
                                            className="border-primary"
                                        />
                                    </FloatingLabel>
                                    <Form.Text className="text-muted"></Form.Text>
                                    {touched.newPassword && errors.newPassword ? (
                                        <span className="error">{errors.newPassword}</span>
                                    ) : null}
                                </Form.Group>
                            </>
                            <Button variant="primary"
                                    type="submit">
                                {loading ? <Spinner animation="border"/> : "Entrar"}
                            </Button>
                            <Button variant="outline-danger"
                                    onClick={() => {
                                        navigate('/dashboard')
                                    }}>
                                Voltar
                            </Button>
                        </Form>
                    </Row>
                </form>
            </Container>
        </>
    )
}

export default Account;